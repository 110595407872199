<template>
  <div>
    <MyHead :nav="-1"></MyHead>
    <!--轮播图-->
    <div class="banner">
      <a-carousel :after-change="onChange" effect="fade" autoplay>
        <div>
          <div class="bannerbox">
            <img class="bannerimg" src="../assets/index/banner_bg.jpg" alt="banner_bg"/>
            <img class="bannerimg2" src="../assets/index/banner_bg2.jpg" alt="banner_bg"/>
            <div class="bannerwindow">
              <h1 class="line1" :style="Language_type == 1 ? '':'font-size:45px;line-height:45px'">
                {{ Language.home.share }}
              </h1>
              <div class="line2">
                {{ Language.home.msg1 }}
              </div>
              <div class="botton" @click="toourServices()">{{ Language.home.Learn_more }}</div>
            </div>
          </div>
        </div>
        <div>
          <img class="bannerimg" src="../assets/index/banner_bg.jpg" alt="banner_bg"/>
          <img class="bannerimg2" src="../assets/index/banner_bg2.jpg" alt="banner_bg"/>
          <div class="bannerwindow">
            <h1 class="line1" :style="Language_type == 1 ? '':'font-size:45px;line-height:45px'">
              {{ Language.home.msg2 }}
            </h1>
            <div class="line2">
              {{ Language.home.msg3 }}
            </div>
            <div class="botton" @click="toserviceProducts">{{ Language.home.Learn_more }}</div>
          </div>
        </div>
        <div>
          <img class="bannerimg" src="../assets/index/banner_bg.jpg" alt="banner_bg"/>
          <img class="bannerimg2" src="../assets/index/banner_bg2.jpg" alt="banner_bg"/>
          <div class="bannerwindow">
            <h1 class="line1" :style="Language_type == 1 ? '':'font-size:45px;line-height:45px'">
              {{ Language.home.msg4 }}
              {{ Language.home.msg42 }}
            </h1>
            <div class="line2">
              {{ Language.home.msg5 }}
            </div>
            <div class="botton" @click="tomanagementSystem()">{{ Language.home.Learn_more }}</div>
          </div>
        </div>
      </a-carousel>
    </div>
    <!--我们的服务-->
    <div class="ourServices">
      <div class="title">
        {{ Language.home.Our_services }}
      </div>
      <div class="Services">
        <div class="item" v-for="(item , index) in fwlist" :key="index" @click="todetail(index)">
          <div class="smalltitle">{{ item.title }}</div>
          <div><img class="img" :src="item.img" :alt="item.title"/></div>
          <div class="learnmore">
            {{ Language.home.Learn_more2 }}
          </div>
        </div>
      </div>
    </div>
    <!--服务产品-->
    <div class="serviceProducts">
      <div class="title">
        {{ Language.home.service_products }}
      </div>
      <div class="Product">
        <div class="item" v-for="(item, index) in cplist" :key="index" @click="tochildProduct(index + 1)">
          <div class="img"><img :src="item.img" :alt="item.title"></div>
          <div class="smalltitle">
            {{ item.title }}
          </div>
          <div class="line" v-for="item in item.lines">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!--子牛质量管理赋能系统-->
    <div class="znsystem">
      <div class="title">{{ Language.home.Ziniu_Quality_Management_Empowerment_System }}</div>
      <div class="description">{{ Language.home.msg6 + Language.home.msg7 }}</div>
<!--      <pre class="description">-->
<!--        {{ Language.home.msg6 }}-->
<!--        {{ Language.home.msg7 }}-->
<!--      </pre>-->
      <div class="navs">
        <div :class="znnav == 2 ? 'nav on':'nav'" @click="changeznnav(2)">{{ Language.home.Ziniu_Quality_Stack }}</div>
        <div :class="znnav == 1 ? 'nav on':'nav'" @click="changeznnav(1)">{{ Language.home.Ziniu_Quality_Chain }}</div>
      </div>
      <div v-if="znnav == 1">
        <img class="img" src="../assets/index/znzlz.png" alt="子牛质量链" style="height: 400px;width: auto"/>
        <div class="description">{{ Language.home.msg8 + Language.home.msg82 }}</div>
<!--        <pre class="description">-->
<!--        {{ Language.home.msg8 }}-->
<!--          {{ Language.home.msg82 }}-->
<!--        </pre>-->
      </div>
      <div v-if="znnav == 2">
        <img class="img" src="../assets/index/znzll.jpg" alt="子牛质量链"/>
        <div class="description">{{ Language.home.msg9 + Language.home.msg92 }}</div>
<!--        <pre class="description">-->
<!--        {{ Language.home.msg9 }}-->
<!--        {{ Language.home.msg92 }}-->
<!--        </pre>-->
      </div>

      <div class="bottonbox">
        <div class="botton1" @click="tocontactUs">{{ Language.home.Appointment_demonstration }}</div>
        <div class="botton2" @click="tologin">{{ Language.home.Login_to_Ziniu }}</div>
      </div>
    </div>
    <!--主要客户-->
    <div class="mainCustom">
      <div class="titlebox">
        <div class="left">{{ Language.home.Clients }}</div>
        <div class="right" @click="toaboutUs"><i class="iconfont icon-single_arrow"></i>{{ Language.home.See_more }}
        </div>
      </div>
      <vue-seamless-scroll style="width: 100%;overflow: hidden" :data="imgs" :class-option="ScrollOption">
        <div class="customs">
          <img v-for="item in imgs" :src="item.img" :alt="item.alt"/>
        </div>
      </vue-seamless-scroll>
    </div>
    <Myfoot></Myfoot>


    <div class="floatrightButton">
      <div>
        <img v-if="Language_type == 1" src="../assets/index/floatright1.jpg" @click="tologin"/>
        <img v-if="Language_type != 1" src="../assets/index/floatright11.jpg" @click="tologin"/>
      </div>
      <div>
        <img v-if="Language_type == 1" src="../assets/index/floatright2.jpg" @click="tocontactUs"/>
        <img v-if="Language_type != 1" src="../assets/index/floatright22.jpg" @click="tocontactUs"/>
      </div>
    </div>
  </div>
</template>

<script>
import MyHead from "../component/myhead"
import Myfoot from "@/component/myfoot";
import vueSeamless from 'vue-seamless-scroll'

export default {
  inject: ['reload', 'L'],
  components: {
    MyHead,
    vueSeamless,
    Myfoot
  },
  data() {
    return {
      Language_type:"",
      imgs: [
        {
          img: require("../assets/LG/7-seven-log0.png"),
          alt: '7-seven-log0'
        },
        {
          img: require("../assets/LG/american-dream-logo.jpg"),
          alt: 'american-dream-logo'
        },
        {
          img: require("../assets/LG/brunnings-logo.png"),
          alt: 'brunnings-logo'
        },
        {
          img: require("../assets/LG/Carrefour_logo.png"),
          alt: 'Carrefour_logo'
        },
        {
          img: require("../assets/LG/cbm-logo.jpg"),
          alt: 'cbm-logo'
        },
        {
          img: require("../assets/LG/dragon-sririt-logo.png"),
          alt: 'dragon-sririt-logo'
        },
        {
          img: require("../assets/LG/intratuin-logo.png"),
          alt: 'intratuin-logo'
        },
        {
          img: require("../assets/LG/jula-logo.png"),
          alt: 'jula-logo'
        },
        {
          img: require("../assets/LG/Logo_Adeo.png"),
          alt: 'Logo_Adeo'
        },
        {
          img: require("../assets/LG/logo-mitama.png"),
          alt: 'logo-mitama'
        },
        {
          img: require("../assets/LG/merchsource-logo.png"),
          alt: 'merchsource-logo'
        },
        {
          img: require("../assets/LG/Mitre-10-logo.png"),
          alt: 'Mitre-10-logo'
        },
        {
          img: require("../assets/LG/namron-logo.jpg"),
          alt: 'namron-logo'
        },
        {
          img: require("../assets/LG/pepco_logo.png"),
          alt: 'pepco_logo'
        },
        {
          img: require("../assets/LG/richelieu-logo.jpg"),
          alt: 'richelieu-logo.jpg'
        },
        {
          img: require("../assets/LG/rosenfeld-logo.png"),
          alt: 'rosenfeld-logo'
        },
        {
          img: require("../assets/LG/siplec-logo.png"),
          alt: 'siplec-logo'
        },
        {
          img: require("../assets/LG/weilai-logo.png"),
          alt: 'weilai-logo'
        },
        {
          img: require("../assets/LG/westim-logo.jpg"),
          alt: 'westim-logo'
        },
      ],
      show: false,
      fwlist: "",
      cplist: "",
      nav2url: [
        {
          title: '节日礼品',
          url: '/products/christmas-lights-and-gifts'
        },
        {
          title: '婴童用品及玩具',
          url: '/products/baby-products-and-toys'
        },
        {
          title: '纺织品',
          url: '/products/apparel-and-textiles'
        },
        {
          title: '户外用品',
          url: '/products/outdoor-goods'
        },
        {
          title: '家具教具',
          url: '/products/furniture'
        },
        {
          title: '电子电气产品',
          url: '/products/electronics'
        },
        {
          title: '食品接触材料',
          url: '/products/food-contact-material'
        }
      ],
      znnav: 2,
    }
  },
  computed: {
    ScrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 7, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    Language() {
      return this.L()
    }
  },
  created() {

  },
  mounted() {
    this.Language_type = localStorage.getItem("Language_type")
    this.fwlist = [
      {
        img: require("../assets/index/fuwu1.jpg"),
        title: this.Language.home.certificate_services,
      },
      {
        img: require("../assets/index/fuwu2.jpg"),
        title: this.Language.home.test_service,
      },
      {
        img: require("../assets/index/fuwu3.jpg"),
        title: this.Language.home.quality_control,
      },
      {
        img: require("../assets/index/fuwu4.jpg"),
        title: this.Language.home.technical_advice,
      },
      {
        img: require("../assets/index/fuwu5.jpg"),
        title: this.Language.home.E_commerce_services,
      },
    ]
    this.cplist = [
      {
        img: require('../assets/index/sd.png'),
        title: this.Language.home.Christmas_lighting_and_gifts,
        lines: [
          this.Language.home.Lighting_and_Gifts,
          this.Language.home.Safety_testing,
          this.Language.home.chemical_test,
          this.Language.home.performance_testing,
          this.Language.home.Reliability_testing,
        ]
      },
      {
        img: require('../assets/index/cp2.png'),
        title: this.Language.home.Infant_and_child_products_and_toys,
        lines: [
          "EN71",
          "STM F963",
          "GB6675",
          "AS/NZS ISO 8124",
        ]
      },
      {
        img: require('../assets/index/cp3.png'),
        title: this.Language.home.textile,
        lines: [
          this.Language.home.Dimensional_stability_testing,
          this.Language.home.component_analysis,
          this.Language.home.Color_fastness_test,
          this.Language.home.Strength_Test,
        ]
      },
      {
        img: require('../assets/index/cp4.png'),
        title: this.Language.home.Outdoor_products,
        lines: [
          this.Language.home.physical_property,
          this.Language.home.Environmental_testing,
          this.Language.home.fire_retardancy_test,
          this.Language.home.Material_testing,
        ]
      },
      {
        img: require('../assets/index/cp5.png'),
        title: this.Language.home.Furniture_teaching_aids,
        lines: [
          this.Language.home.environmental_performance,
          this.Language.home.physical_property,
          this.Language.home.External_dimensions_and_deviation_detection,
        ]
      },
      {
        img: require('../assets/index/cp6.png'),
        title: this.Language.home.electrical_and_electronic_product,
        lines: [
          this.Language.home.Climate_environment_testing,
          this.Language.home.Mechanical_environment_testing,
          this.Language.home.Comprehensive_environmental_testing,
          this.Language.home.Packaging_materials_and_transportation_testing,
        ]
      },
      {
        img: require('../assets/index/cp7.png'),
        title: this.Language.home.Food_contact_materials,
        lines: [
          this.Language.home.tableware,
          this.Language.home.Kitchenware,
          this.Language.home.Packaging,
          this.Language.home.CHILDREN,
        ]
      },
    ]


  },
  beforeDestroy() {

  },
  watch: {},
  methods: {
    onChange(e) {
      // console.log(e)
    },
    todetail(index) {
      if (index == 0) {
        this.$router.push("/certification")
      }
      if (index == 1) {
        this.$router.push("/products")
      }
      if (index == 2) {
        this.$router.push("/our-services/quality-control-management")
      }
      if (index == 3) {
        this.$router.push("/our-services/technical-consulting")
      }
      if (index == 4) {
        this.$router.push("/our-services/e-commerce")
      }
    },
    tocontactUs() {
      this.$router.push("/contact")
    },
    tologin() {
      window.location.href = 'https://qc-plus.cn/admin/user/login?language=' + localStorage.getItem("Language_type")
    },
    toourServices() {
      this.$router.push("/our-services")
    },
    toserviceProducts() {
      this.$router.push("/products")
    },
    tomanagementSystem() {
      this.$router.push("/our-services/quality-control-management")
    },
    changeznnav(e) {
      this.znnav = e
    },
    toaboutUs() {
      if (this.$route.name != 'aboutUs') {
        localStorage.setItem("LocalID", "about3")
        this.$router.push("/about-us")
      }
    },
    tochildProduct(e) {
      this.$router.push(this.nav2url[e - 1].url)
    },
  },
}
</script>

<style lang="scss" scoped>


.bannerimg{
  display: block;
}
.bannerimg2{
  display: none !important;
  width: 100%;
}

.floatrightButton {
  position: fixed;
  right: 0;
  top: 40vh;

  img {
    width: 96px;
    height: 79px;
    margin-top: 5px;
    cursor: pointer;
  }
}

.btn {
  position: fixed;
  bottom: 15px;
  left: 100px;
  right: 100px;
  background-color: #1989fa;
  width: calc(100% - 200px);
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  color: #FFFFFF;
}

//轮播图
.banner {
  margin-top: -70.5px;
  position: relative;

  .bannerimg {
    width: 100%;
  }

  .bannerwindow {
    position: absolute;
    top: 35%;
    left: calc(50% - (576px / 2));
    width: 576px;
    text-align: center;
    color: #FFFFFF;

    .line1 {
      font-size: 72px;
      line-height: 90px;
      color: #fff;
    }

    .line2 {
      font-size: 24px;
      margin-top: 10px;
      letter-spacing: 6px;
    }

    .botton {
      width: 205px;
      text-align: center;
      background-color: #00AD61;
      line-height: 50px;
      border-radius: 100px;
      font-size: 18px;
      margin: auto;
      margin-top: 40px;
    }

    .botton:hover {
      background-color: #1D9067;
    }
  }
}

//我们的服务
.ourServices {
  .title {
    color: #333333;
    font-family: 微软雅黑;
    font-size: 42px;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 60px;
  }

  .Services {
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      width: calc((100% - 120px) / 5);
      background-color: #f9f9fa;
      padding-bottom: 40px;

      .smalltitle {
        color: #FFFFFF;
        font-family: 微软雅黑;
        font-weight: bold;
        font-size: 18px;
        line-height: 40px;
        letter-spacing: 0px;
        text-align: center;
        background-color: #3C3C3B;
      }

      .img {
        width: 100%;
      }

      .learnmore {
        width: calc(100% - 20px);
        height: 50px;
        border: 1px solid #27BA69;
        border-radius: 25px;
        text-align: center;
        line-height: 50px;
        color: #27BA69;
        margin: auto;
        margin-top: 30px;
        cursor: pointer;
      }

      .learnmore:hover {
        background-color: #00ad60;
        color: #FFFFFF;
      }
    }
  }
}

//服务产品
.serviceProducts {
  .title {
    color: #333333;
    font-family: 微软雅黑;
    font-size: 42px;
    line-height: normal;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 60px;
  }

  .Product {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    flex-wrap: wrap;

    .item:hover {
      background-color: #ececec;
    }

    .item {
      cursor: pointer;
      width: calc((100% - 180px) / 7);
      text-align: center;
      background-color: #FAFAFA;
      padding-bottom: 28.5px;
      border-bottom: 2px solid #00AD61;

      .img {
        padding: 28.5px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .smalltitle {
        font-weight: bold;
        font-size: 18px;
        color: #333333;
        margin-bottom: 15px;
      }

      .line {
        color: #333333;
        font-size: 14px;

      }
    }
  }
}

//子牛质量管理赋能系统
.znsystem {
  background-color: #f9f9fa;
  padding: 60px 30px;
  text-align: center;

  .title {
    font-size: 45px;
    color: #333333;
  }

  .description {
    font-size: 16px;
    color: #333333;
    margin-top: 10px;
  }

  .navs {
    display: flex;
    width: 400px;
    margin: auto;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;

    .nav {
      padding-bottom: 10px;
    }

    .on {
      color: #00AD61;
      border-bottom: 2px solid #00AD61;
    }
  }

  .img {
    width: 100%;
    margin-top: 20px;
  }

  .bottonbox {
    display: flex;
    width: 446px;
    margin: auto;
    justify-content: space-between;

    .botton1 {
      background-color: #00AD61;
      width: 205px;
      line-height: 50px;
      font-size: 18px;
      color: #FFFFFF;
      border-radius: 25px;
    }

    .botton1:hover {
      background-color: #1d9067;
    }

    .botton2 {
      background-color: #00AD61;
      width: 205px;
      line-height: 50px;
      font-size: 18px;
      color: #FFFFFF;
      border-radius: 25px;
    }

    .botton2:hover {
      background-color: #1d9067;
    }
  }
}

//主要客户
.mainCustom {
  padding-top: 80px;

  .titlebox {
    width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items: baseline;

    .left {
      font-size: 42px;
      color: #333333;
    }

    .right {
      font-size: 12px;
      color: #27BA69;
      cursor: pointer;
    }
  }

  .scrollbox {
    width: 2000px;
    padding: 80px 0;
    overflow: hidden;
  }

  .customs {
    padding: 80px 0;
    display: flex;

    img {
      flex-shrink: 0;
      width: 200px;
      height: 80px;
      margin-right: 50px;
      object-fit: contain;
    }
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media (min-width: 1000px) and (max-width: 1320px) {
  .bannerimg{
    display: block !important;
  }
  .bannerimg2{
    display: none !important;
  }
  .bannerwindow {
    top: 30% !important;

    .line1 {
      font-size:45px !important;
      line-height: 50px !important;
    }
  }
  .Product {
    .item {
      .smalltitle {
        height: 40px;
      }
    }
  }
  .mainCustom {
    .titlebox {
      width: calc(100% - 20px) !important;
    }
  }
}

@media (min-width: 800px) and (max-width: 1000px) {
  .bannerimg{
    display: block !important;
  }
  .bannerimg2{
    display: none !important;
  }
  .bannerwindow {
    top: 30% !important;

    .line1 {
      font-size: 35px !important;
      line-height: 50px !important;
    }

    .botton {
      margin-top: 20px !important;
      font-size: 14px !important;
    }
  }
  .Product {
    .item {
      .smalltitle {
        height: 45px;
      }
    }
  }
  .ourServices {
    .title {
      font-size: 30px;
    }

    .Services {
      .smalltitle {
        font-size: 14px !important;
      }

      .learnmore {
        height: 35px !important;
        line-height: 35px !important;
        font-size: 12px !important;
        margin-top: 10px !important;
      }

      .item {
        padding-bottom: 10px;
      }
    }
  }
  .serviceProducts {
    .title {
      font-size: 30px;
    }

    .Product {
      .item {
        .img {
          padding: 10px 0px;
        }

        .smalltitle {
          font-size: 14px;
          padding: 0px 5px;
        }

        .line {
          font-size: 10px;
        }
      }
    }
  }
  .mainCustom {
    .titlebox {
      width: calc(100% - 20px) !important;
    }
  }
  .znsystem {
    .title {
      font-size: 30px;
    }

    .description {
      font-size: 14px;
    }

    .navs {
      .nav {
        font-size: 12px;
      }
    }

    .bottonbox {
      .botton1 {
        font-size: 15px;
        width: 150px;
        line-height: 40px;
      }

      .botton2 {
        font-size: 15px;
        width: 150px;
        line-height: 40px;
      }
    }
  }
  .mainCustom {
    .titlebox {
      .left {
        font-size: 30px;
      }
    }
  }
}

@media (min-width: 500px) and (max-width: 800px) {
  .banner{
    margin-top: 0px!important;
  }
  .bannerimg{
    display: block !important;
  }
  .bannerimg2{
    display: none !important;
  }
  .bannerwindow {
    top: 30% !important;

    .line1 {
      font-size: 20px !important;
      line-height: 50px !important;
    }
    .line2{
      font-size: 15px !important;
    }
    .botton {
      margin-top: 20px !important;
      font-size: 14px !important;
      width: 150px !important;
      line-height: 35px !important;
    }
  }
  .Product {
    .item {
      .smalltitle {
        height: 45px;
      }
    }
  }
  .ourServices {
    .title {
      font-size: 20px;
    }

    .Services {
      flex-wrap: wrap;
      .smalltitle {
        font-size: 12px !important;
      }

      .learnmore {
        height: 35px !important;
        line-height: 35px !important;
        font-size: 12px !important;
        margin-top: 10px !important;
      }

      .item {
        padding-bottom: 10px;
        width: calc(50% - 5px);
        margin-bottom: 20px;
      }
    }
  }
  .serviceProducts {
    .title {
      font-size: 20px;
    }

    .Product {
      flex-wrap: wrap;
      .item {
        width: calc(33.3% - 7px);
        margin-bottom: 20px;
        .img {
          padding: 10px 0px;
        }

        .smalltitle {
          font-size: 14px;
          padding: 0px 5px;
        }

        .line {
          font-size: 10px;
        }
      }
    }
  }
  .mainCustom {
    .titlebox {
      width: calc(100% - 20px) !important;
    }
  }
  .znsystem {
    .title {
      font-size: 20px;
    }

    .description {
      font-size: 12px;
    }

    .navs {
      width: 250px;
      margin-top: 20px;
      .nav {
        font-size: 12px;
      }
    }

    .bottonbox {
      width: 350px;
      margin-top: 20px;
      .botton1 {
        font-size: 15px;
        width: 150px;
        line-height: 40px;
      }

      .botton2 {
        font-size: 15px;
        width: 150px;
        line-height: 40px;
      }
    }
  }
  .mainCustom {
    .titlebox {
      .left {
        font-size: 30px;
      }
    }
    .customs{
      padding: 20px 0px;
    }
  }
}

@media (min-width: 0px) and (max-width: 500px) {

  .banner{
    margin-top: 0px !important;
  }
  .bannerimg{
    display: none !important;
  }
  .bannerimg2{
    display: block !important;
  }
  .bannerwindow {
    top: 30% !important;

    .line1 {
      font-size: 25px !important;
      line-height: 50px !important;
    }
    .line2{
      font-size: 15px !important;
    }
    .botton {
      margin-top: 20px !important;
      font-size: 10px !important;
      width: 100px !important;
      line-height: 30px !important;
    }
  }
  .Product {
    .item {
      .smalltitle {
        height: 45px;
      }
    }
  }
  .ourServices {
    .title {
      margin-top: 30px;
      font-size: 25px;
    }

    .Services {
      flex-wrap: wrap;
      padding: 30px 0px;
      .smalltitle {
        font-size: 16px !important;
      }

      .learnmore {
        height: 35px !important;
        line-height: 35px !important;
        font-size: 12px !important;
        margin-top: 10px !important;
      }

      .item {
        padding-bottom: 10px;
        width: calc(100%);
        margin-bottom: 20px;
      }
    }
  }
  .serviceProducts {
    .title {
      margin-top: 0px;
      font-size: 25px;
    }

    .Product {
      flex-wrap: wrap;
      padding: 30px 0px;
      .item {
        width: calc(50% - 7px);
        margin-bottom: 20px;
        .img {
          padding: 10px 0px;
        }

        .smalltitle {
          font-size: 14px;
          padding: 0px 5px;
        }

        .line {
          font-size: 10px;
        }
      }
    }
  }
  .mainCustom {
    .titlebox {
      width: calc(100% - 20px) !important;
    }
  }
  .znsystem {
    .title {
      font-size: 25px;
    }

    .description {
      font-size: 12px;
    }

    .navs {
      width: 250px;
      margin-top: 20px;
      .nav {
        font-size: 12px;
      }
    }

    .bottonbox {
      width: 100%;
      margin-top: 20px;
      .botton1 {
        font-size: 12px;
        width: 100px;
        line-height: 30px;
      }

      .botton2 {
        font-size: 12px;
        width: 100px;
        line-height: 30px;
      }
    }
  }
  .mainCustom {
    .titlebox {
      .left {
        font-size: 20px;
      }
    }
  }
}
</style>